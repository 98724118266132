

const HelloStrangers = () => {
    return (
        <div>
            Hello, strangers...
        </div>
    )
}


export default HelloStrangers;